.modal-backdrop {
  background-color: $backdrop-bg-color;
  &.in {
    opacity: .8;
  }
}

.modal--2k18 {
  .modal-content {
    border-color: #fff;
    border-radius: 0;
    box-shadow: 0 10px 40px rgba(0, 0, 0, .3);
  }

  .modal-header {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .modal-title {
    @include make-title-3();
  }

  .modal-text {
    color: $text-muted;
  }

  .modal-footer {
    border-top: 0;
    padding-top: 0;
  }
}

.modal--delete-action {
  .modal-dialog {
    margin-top: 120px;
    width: 420px;
  }

  .modal-text {
    margin: 0;
  }
}
