@mixin make-layout($aside-width) {
  .layout-header {
    .butterbar {
      left: $aside-width;
    }

    .navbar-brand {
      width: $aside-width;
    }

    .navbar-knob {
      width: $aside-width;
    }
  }

  .layout-aside--left {
    width: $aside-width;

    .offie-layer {
      width: $aside-width;
    }
  }
}

@mixin make-layout-variant($header-bg-color, $brand-bg-color, $aside-left-bg-color, $content-bg-color, $backdrop-bg-color: #000, $backdrop-opacity: 0.5) {
  .layout-content {
    background-color: $content-bg-color;
  }

  .offie-container {
    &:before {
      background: $backdrop-bg-color;
    }

    &.offie--active {
      &:before {
        opacity: $backdrop-opacity;
      }
    }
  }

  .layout-aside--left {
    &,
    .offie-layer {
      background-color: $aside-left-bg-color;
    }
  }
}
