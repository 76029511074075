$brand-primary:                 #BE1922;

$brand-secondary:               #7E8083;
$brand-secondary-lt:            lighten(#7E8083, 15%);

$brand-info:                    #4D8CFD;

$brand-danger:                  #BE1922;

$brand-warning:                 #EE964B;

$brand-success:                 #12C445;

$brand-dark:                    #2A2F35;
$brand-dark-dk:                 darken($brand-dark, 5%);
$brand-light:                   #F6F7FD;
$brand-light-dk:                #EFF1F9;
$brand-light-dker:              #E4E9EC;

$link-color:                    $brand-info;
$text-color:                    #383D3B;
$text-muted:                    #7E8083;

$border-color:                  $brand-light-dk;

$font-size-base:                14px;
$font-size-xs:                  $font-size-base * 0.80;
$font-size-sm:                  $font-size-base * 0.85;
$font-size-md:                  $font-size-base * 1.20;
$font-size-lg:                  $font-size-base * 1.42;

// Grid
$grid-float-breakpoint: 768px;

// Navbars
$navbar-app-header-height:      50px;
$navbar-user-img-size:          35px;
$navbar-user-spacer-y:          calc(($navbar-app-header-height - $navbar-user-img-size) / 2);
$navbar-user-spacer-x:          $navbar-user-spacer-y;

// Sections
$section-spacer-bottom:         20px;
$section-spacer-y:              $section-spacer-bottom;

$section-prime-space-x:         15px;

// Cards
$card-default-border:           1px solid $border-color;
$card-default-border-radius:    4px;
$card-default-box-shadow:       0 0 10px 0 rgba(0, 0, 0, 0.04);
$card-default-spacer-y:         15px;

$card-channel-spacer-bottom:    7px;
$card-channel-border:           $card-default-border;
$card-channel-border-radius:    $card-default-border-radius;
$card-channel-box-shadow:       $card-default-box-shadow;
$card-channel-decorator-height: 8px;
$card-channel-decorator-width:  50px;

// Flashmessages
$flashmessage-border-radius:    2px;
$flashmessage-border-size:      1px;

// Offie
$offie-layer-zindex:            1031;
$offie-animation-timing:        cubic-bezier(0.190, 1.000, 0.220, 1.000)/*cubic-bezier(1.000, -0.395, 0.200, 1.245)*/;

// Layout
$backdrop-opacity:              0.7;
$backdrop-zindex:               1020;

$content-bg-color:              #fff;

// Workplace card colors
$workplace-border:              #dddfe2;
$workplace-title:               #4b4f56;
$workplace-description:         #90949c;
$workplace-background:          #f6f7f9;

// Buttons
$btn-text-over-brand-primary:   #fff;

// Colors
$green:                          #00C446;
$light-green:                    #96CD41;
$blue:                           #2C6CD7;
$med-blue:                       #4872D8;
$light-blue:                     #6867CE;
$grey:                           #C3C4C7;
$red:                            #BE1922;
