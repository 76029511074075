.newsletter-subjects {
  .switch-btn {
    position: relative;
    display: block;
    width: 25px;
    height: 15px;
    padding: 0;
    margin: 0 0 0 10px;
    border-radius: 18px;
    cursor: pointer;
  }

  .checked-switch {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked {
      ~ .text-switch {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      ~ .toggle-btn {
        background-color: #497fdc;
        left: 11px;
      }
    }
  }

  .text-switch, .toggle-btn {
    transition: all 0.3s ease;
  }

  .text-switch {
    background-color: #e4eaec;
    border: 1px solid #e4eaec;
    border-radius: inherit;
    color: #fff;
    display: block;
    height: inherit;
    position: relative;
  }

  .toggle-btn {
    position: absolute;
    display: block;
    width: 13px;
    height: 13px;
    top: 1px;
    left: 1px;
    background: #696B70;
    border-radius: 100%;

    &:before {
      color: #aaaaaa;
      content: "";
      display: inline-block;
      font-size: 13px;
      letter-spacing: -2px;
      padding: 6px 0;
      vertical-align: middle;
    }
  }
}

.subjects-container {
  margin-top: 30px;
}

.subject-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid #eee;
  border-radius: 6px;

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 15px;
    height: 15px;
    fill: #ccc;
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: inherit;
    margin: 0 0 0 15px;
  }

  .subject-item--edit {
    line-height: 1;
    color: #497fdc;
  }
}