.flashmessage {
  border: $flashmessage-border-size solid $border-color;
  border-radius: $flashmessage-border-radius;
  box-shadow: 0 2px 5px 0 rgba(#000, 0.05);
  position: relative;

  &:before {
    background-color: $brand-info;
    border-radius: $flashmessage-border-radius 0 0 $flashmessage-border-radius;
    bottom: -$flashmessage-border-size;
    content: '';
    left: -$flashmessage-border-size;
    position: absolute;
    top: -$flashmessage-border-size;
    width: 4px;
  }
}

.flashmessage-body {
  padding: 12px 20px;

  > *:last-child {
    margin-bottom: 0;
  }
}

.flashmessage-text,
.flashmessage-title {
  margin-bottom: 5px;
}

.flashmessage-title {
  @include make-title-4();
}

.flashmessage-btn {
  @include link-action();
  // float: right;
  line-height: 32px;
}

@mixin make-flashmessage-variant($color) {
  border-bottom-color: $color;
  .flashmessage-title {
    color: $color;
  }

  &:before {
    background-color: $color;
  }
}

.flashmessage--error {
  @include make-flashmessage-variant($brand-danger);
}

.flashmessage--success {
  @include make-flashmessage-variant($brand-success);
}

.flashmessage--warning {
  @include make-flashmessage-variant($brand-warning);
}

.flashmessage--tiptop {
  .flashmessage-body {
    padding-top: 25px;
  }
}

@mixin make-css-triangle($colour1, $colour2, $direction, $size, $borderSize) {
  position: relative;
  border: $borderSize solid $colour2;
  &:before,
  &:after {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    @if $direction == 'bottom' {
      top: 100%;
      left: 50%;
    }
    @if $direction == 'top' {
      bottom: 100%;
      left: 50%;
    }
    @if $direction == 'left'{
      right: 100%;
      top: 50%;
    }
    @if $direction == 'right'{
      left: 100%;
      top: 50%;
    }
  }
  &:after{
    border-color: rgba($colour1, 0);
    border-width: $size;
    @if $direction == 'bottom'{
      border-top-color: $colour1;
      margin-left: 0px - $size;
    }
    @if $direction == 'top'{
      border-bottom-color: $colour1;
      margin-left: 0px - $size;
    }
    @if $direction == 'right'{
      border-left-color: $colour1;
      margin-top: 0px - $size;
    }
    @if $direction == 'left'{
      border-right-color: $colour1;
      margin-top: 0px - $size;
    }
  }
  &:before {
    $bArrowWidth: $size + round($borderSize * 1.4142135623730951);
    border-color: rgba($colour1, 0);
    border-width: $bArrowWidth;
    @if $direction == 'bottom'{
      border-top-color: $colour2;
      margin-left: 0px - $bArrowWidth;
    }
    @if $direction == 'top'{
      border-bottom-color: $colour2;
      margin-left: 0px - $bArrowWidth;
    }
    @if $direction == 'right'{
      border-left-color: $colour2;
      margin-top: 0px - $bArrowWidth;
    }
    @if $direction == 'left'{
      border-right-color: $colour2;
      margin-top: 0px - $bArrowWidth;
    }
  }
}

.flashmessage-tip {
  position: absolute;
  top: -12px;
  left: 20px;
}

.notify {
  border: 1px solid $border-color;
  border-radius: 2px;
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  vertical-align: middle;


  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.05);

  svg {
    fill: $brand-danger;
    height: 12px;
    width: 12px;
  }

  svg,
  .notify-text {
    color: $brand-danger;
    display: inline-block;
    vertical-align: middle;
  }

  .notify-text--error {
    color: $brand-danger;
    fill: $brand-danger;
  }

  .notify-text--warning {
    color: $brand-warning;
    fill: $brand-warning;
  }

  padding: 5px;
  background: $brand-light;
  border: 1px solid $brand-light-dker;
}

$notify-sides: (
  'bottom': 'top',
  'left':   'right',
  'right':  'left',
  'top':    'bottom',
);

$notify-variants: (
  'danger': (
    'bg-color': $brand-danger,
    'border-color': $brand-danger,
    'text-color': #fff,
  ),
  'dangerlt': (
    'bg-color': #fff,
    'border-color': $brand-danger,
    'text-color': $brand-danger,
  ),
  'success': (
    'bg-color': $brand-success,
    'border-color': $brand-success,
    'text-color': #fff,
  )
);

@mixin make-notify-variant($bg-color, $border-color, $text-color) {
  background-color: $bg-color;
  border-color: $border-color;

  .notify-text {
    color: $text-color;
  }

  .wizicon {
    fill: $text-color;
  }

  @each $side, $border in $notify-sides {
    &.notify--#{$side} {
      &:before {
        border-#{$border}-color: $border-color;
      }

      &:after {
        border-#{$border}-color: $bg-color;
      }
    }
  }
}

@each $side, $inverse in $notify-sides {
  .notify--#{$side} {
    $parameters: (
      size: 5px,
      direction: $side,
      colour1: $brand-light,
      colour2: $brand-light-dker,
      borderSize: 1px
    );
    @include make-css-triangle($parameters...);
  }
}

@each $variant, $variant-color in $notify-variants {
  .notify--#{$variant} {
    @include make-notify-variant($variant-color...);
  }
}
