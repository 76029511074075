@mixin make-offie-layer($bg-color: #fff, $width: 340px, $zindex: '') {
  background: $bg-color;
  height: 100%;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: $width;

  -webkit-transition: all 0.35s, opacity 0.25s;
  transition: all 0.35s, opacity 0.25s;

  @if $zindex != '' {
    z-index: $offie-layer-zindex;
  }
}

@mixin make-offie-dropdown($bg-color: #fff, $width: 340px, $zindex: '') {
  opacity: 0;
  position: absolute;
  right: 0;
  visibility: hidden;
  width: $width;

  -webkit-transition: all 0.35s, opacity 0.25s;
  transition: all 0.35s, opacity 0.25s;

  @if $zindex != '' {
    z-index: $offie-layer-zindex;
  }

  &.offie--active {
    visibility: visible;
    opacity: 1;
  }
}

@mixin make-offie-layer-effect1() {
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);

    -webkit-transition-timing-function: cubic-bezier(1.000, 0, 0.200, 1); /* older webkit */
    -webkit-transition-timing-function: $offie-animation-timing;
    -moz-transition-timing-function: $offie-animation-timing;
    -o-transition-timing-function: $offie-animation-timing;
  transition-timing-function: $offie-animation-timing; /* custom */

  &.offie--active {
    visibility: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@mixin make-offie-layer-effect2() {
  left: auto;
  right: 0;
  visibility: hidden;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

    -webkit-transition-timing-function: cubic-bezier(1.000, 0, 0.200, 1); /* older webkit */
    -webkit-transition-timing-function: $offie-animation-timing;
    -moz-transition-timing-function: $offie-animation-timing;
    -o-transition-timing-function: $offie-animation-timing;
  transition-timing-function: $offie-animation-timing; /* custom */

  &.offie--active {
    visibility: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
