#rss-feeds-fields-list {

    .toggle-RSS-available .switch {
        margin: 10px auto;
    }

    .action {
        label {
            display: block;
            text-align: center;
        }

        .update-btn {
            display: block;
            margin: 10.5px auto;
        }
    }
}
