@mixin make-button-variant($bg-color, $text-color, $bg-color--hover, $text-color--hover)
{
  background-color: $bg-color;
  color: $text-color;

  &:focus,
  &:hover {
    background-color: $bg-color--hover;
    color: $text-color--hover;
  }
}
