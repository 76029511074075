.nav--main {
  .nav-item-text {
    display: block;
    padding: 0 10px;
    overflow: hidden;
  }

  .nav-item-icon--primary {
    float: left;
  }

  .nav-item-icon--collapse {
    @include make-nav-icon-variant(12px, $aside-left-icon-color, #fff, '', 3px 0);
    float: right;

    svg {
      transition: transform 0.25s;
    }
  }

  .offie--active > .nav-item-icon--collapse svg {
    transform: rotateZ(45deg);
  }

  .nav-link:active,
  .nav-link:focus,
  .nav-link:hover,
  .active {
    // Resets bootstrap focus / active background color.
    background-color: transparent;
  }

  &.nav--lvl2 {
    .nav-link {
      padding: 8px 0;

      @media screen and (min-width: 992px) {
        padding: 3px 0px;
      }
    }
  }

  .offie-collapse-body {
    padding: 5px 20px 15px 20px;
  }

  .notify {
    font-size: 10px;
    margin-left: 7px;
    padding: 2px 3px;
  }
}

.nav--useractivity {
  > li,
  > .nav-item {
    padding: 10px;

    > a,
    > .nav-link {
      padding: 0;
      text-align: right;

      &:focus,
      &:hover {
        background-color: transparent;
      }
    }

    > .nav-link {
      @include link-action();
    }
  }

  .nav-item + .nav-item {
    border-top: 1px solid $border-color;
  }
}

.nav-techbar {
  background: #000;
  border-radius: 0 30px 30px 0;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15);
  padding-right: 5px;

  .nav-item {
    float: left;
    padding: 5px 7px;
  }

  .nav-item-icon {
    background-color: #fff;
    border-radius: 25px;
    display: inline-block;
    margin-right: 5px;
    padding: 3px;
    vertical-align: middle;

    .wizicon {
      display: block;
      fill: #000;
      height: 15px;
      width: 15px;
    }
  }

  .nav-item-text {
    color: #fff;
    display: inline-block;
    font-family: monospace;
    font-size: 11px;
    vertical-align: middle;
  }
}

.nav--table-actions {
  white-space: nowrap;

  .nav-item {
    display: inline-block;
  }

  .nav-item + .nav-item {
    margin-left: 7px;
  }

  .nav-link {
    padding: 0;

    // text-transform: uppercase;

    &:focus,
    &:hover {
      background-color: transparent;
    }
  }
}
