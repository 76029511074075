@keyframes glow {
  0%   {opacity: 0.10;}
  20%  {opacity: 0.12;}
  40%  {opacity: 0.15;}
  60%  {opacity: 0.18;}
  80%  {opacity: 0.15;}
  100% {opacity: 0.12;}
}

.smoothie-placeholder {
  animation: glow 1.5s infinite;
  fill: #ccc;
  opacity: 0.15;
  z-index: 1034;
}

.smoothie--ready {
  display: none;
}

.smoothie-placeholder--svg4everybody {
  position: absolute;
}

.wizsvg-fingerprint-reduit .smoothie-placeholder {
  display: none; // Fix for upload.js code.
}
