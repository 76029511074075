body {
  background-color: $body-bg-color;
}

.link { // Should be a[href], .link but legacy code is breaking.
  @include make-link();
  @include link-action();
}

.link--danger {
  @include link-action--danger();
}
