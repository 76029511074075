.spokespersons-container {
  .check-sender-validity {
    display: inline-block;
    margin-bottom: 15px;
  }

  .reorder-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .switch-btn {
    position: relative;
    display: block;
    width: 35px;
    height: 20px;
    padding: 0;
    margin: 0 0 0 10px;
    border-radius: 18px;
    cursor: pointer;
  }

  .checked-switch {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked {
      ~ .text-switch {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      ~ .toggle-btn {
        background-color: #497fdc;
        left: 16px;
      }
    }
  }

  .text-switch, .toggle-btn {
    transition: all 0.3s ease;
  }

  .text-switch {
    background-color: #e4eaec;
    border: 1px solid #e4eaec;
    border-radius: inherit;
    color: #fff;
    display: block;
    height: inherit;
    position: relative;
  }

  .toggle-btn {
    position: absolute;
    display: block;
    width: 18px;
    height: 18px;
    top: 1px;
    left: 1px;
    background: #696B70;
    border-radius: 100%;
  }
}

.spokespersons-list {
  margin-top: 20px;
}

.spokesperson-reorder-toggle {
  display: flex;
  align-items: center;
}

.spokesperson-card {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #fff;
  margin-bottom: 15px;
  border: 1px solid #eeeeee;
  border-radius: 6px;

  &.sortable {
    cursor: move;
    user-select: none;
  }

  .spokesperson-rank {
    display: flex;
    width: 25px;
    height: 25px;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    margin-right: 15px;
    border-radius: 50%;
  }

  .dnd-handle-icon {
    width: 15px;
    height: 15px;
    fill: #ccc;
    margin-right: 15px;
  }

  .spokesperson-avatar {
    position: relative;
    width: 40px;
    height: 40px;

    .avatar-background {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .spokesperson-info {
    display: flex;
    align-items: center;

    h4 {
      font-size: 14px;
      font-weight: 600;
      margin: 0 0 0 15px;

      + i {
        margin-left: 15px;
      }
    }
  }

  .spokesperson-tags {
    display: flex;
    padding: 0 15px;
  }

  .spokesperson-badge {
    display: block;
    font-size: 11px;
    color: #fff;
    padding: 5px;
    background-color: #4D4D4D;
    border-radius: 4px;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  .spokesperson-card--actions {
    margin-left: auto;

    .btn {
      letter-spacing: 0;
      text-transform: capitalize;
      border: none;

      &:before {
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        font-size: 12px;
        padding-right: 0;
        vertical-align: initial;
      }
    }

    > a {
      margin-left: 15px;
    }
  }
}
