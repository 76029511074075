#swagger-ui.api-platform {
  /**  INCREASE WRAPPER BLOC PADDING **/
  &.wrapper {
    padding: 0px 60px;
  }

  /**  INFORMATIONS BLOC **/
  &.information-container.wrapper {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    margin: 0 0 30px 0;
    max-width: 100%;
    padding: 10px 0 0;
    width: 100%;
  }

  &.info {
    margin: 0px auto;
    max-width: 1460px;
    padding: 0px 50px;
    width: 100%;
  }

  /**  METHODS BLOCS **/
  &.opblock.opblock-get {
    &.opblock-summary-method {
      background-color: #3caab5;
    }

    &.opblock-summary {
      border-color: #3caab5;
    }
  }

  &.opblock.opblock-put {
    &.opblock-summary-method {
      background-color: #e6c229;
    }

    &.opblock-summary {
      border-color: #e6c229;
    }
  }

  &.opblock.opblock-post {
    &.opblock-summary-method {
      background-color: #78bc61;
    }

    &.opblock-summary {
      border-color: #78bc61;
    }
  }

  &.opblock.opblock-delete {
    &.opblock-summary-method {
      background-color: #ed6a5a;
    }

    &.opblock-summary {
      border-color: #ed6a5a;
    }
  }

  &.opblock.opblock-deprecated {
    &.opblock-summary-method {
      background-color: #ebebeb;
    }

    &.opblock-summary {
      border-color: #ebebeb;
    }
  }

  &.opblock-summary-method {
    border-radius: 0;
    padding: 10px;
  }

  &.opblock-summary {
    padding: 0;
  }

  &.opblock-tag {
    padding: 5px 0;
    margin: 0 0 10px;
  }

  &.opblock-tag:hover {
    background-color: rgba(0, 0, 0, .1);
    transform: scale(1.01);
  }

  &.opblock-section-header,
  .opblock.opblock-get .opblock-section-header {
    background-color: rgba(60, 170, 181, 0.1);
    box-shadow: none;
  }

  &.opblock {
    background-color: #fff;
    border-radius: 0;
    border: none !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin: 0 0 10px;
    padding: 0;

    .opblock-post .opblock-section-header {
      background-color: rgba(120, 188, 97, 0.1);
    }

    .opblock-put .opblock-section-header {
      background-color: rgba(230, 194, 41, 0.1);
    }

    .opblock-delete .opblock-section-header {
      background-color: rgba(237, 106, 90, 0.1);
    }

    .opblock-deprecated .opblock-section-header {
      background-color: rgba(235, 235, 235, 0.1);
    }
  }

  /** BUTTONS **/
  &.btn {
    .execute {
      animation: none;
      background-color: #3caab5;
      border-color: #3caab5;
      transition: all ease 0.3s;
    }

    &:hover {
      background-color: #288690;
      border-color: #288690;
    }
  }

  &.execute-wrapper {
    text-align: center;

    &.btn {
      padding: 10px 40px;
      width: auto;
    }
  }

  &.btn-group {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;

    &.btn {
      padding: 10px 40px;
    }
  }

  &.btn {
    background-color: #f7f7f7;
    box-shadow: none;
    transition: all ease 0.2s;

    &:hover {
      background-color: rgba(65, 68, 78, 0.1);
      border-color: transparent;
    }

    .cancel:hover {
      background-color: rgba(237, 106, 90, 0.1);
    }

    .authorize:hover {
      background-color: rgba(120, 188, 97, 0.1);
    }
  }

  &select {
    box-shadow: none;
    cursor: pointer;
  }

  /** FIX TABS SEPARATOR **/
  &.tab {
    &li:first-of-type:after {
      content: none;
    }

    &li {
      border-right: 1px solid rgba(0, 0, 0, .2);
      padding: 0px 5px;
    }

    &li:last-of-type {
      border-right: none;
    }
  }

  /** REMOVE HIGHLIGHTS FOCUS INPUTS **/
  &input:focus,
  &select:focus,
  &textarea:focus,
  &button:focus {
    outline: none;
  }
}

#swagger-ui .topbar {
  display: none;
}

/* Some HACK-FIXES */
.swagger-ui {
  /* Hide bad display of box-shadows */
  .scheme-container {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  /* Hide OPTIONS method sections */
  .opblock.opblock-options {
    display: none;
  }

  /* Hide version */
  .info .title small {
    display: none !important;
  }
}

/** FORMATS **/

#formats {
  font-family: Open Sans, sans-serif;
  margin: 0 auto;
  max-width: 1460px;
  padding: 0px 60px;
  text-align: right;
  width: 100%;
}

/** REMOVE TITILIUM FONT **/
.swagger-ui {
  &.opblock-tag,
  &.opblock .opblock-section-header label,
  &.opblock .opblock-section-header h4,
  &.opblock .opblock-summary-method,
  &.tab li,
  &.scheme-container .schemes>label,
  &.loading-container .loading:after,
  &.btn,
  &.btn.cancel,
  &select,
  &label,
  &.dialog-ux .modal-ux-content h4,
  &.dialog-ux .modal-ux-header h3,
  &section.models h4,
  &section.models h5,
  &.model-title,
  &.parameter__name,
  &.topbar a,
  &.topbar .download-url-wrapper .download-url-button,
  &.info .title small pre,
  &.scopes h2,
  &.errors-wrapper hgroup h4 {
    font-family: Open Sans, sans-serif !important;
  }
}
