/***** SOCIAL *****/
#publication_write {
  textarea.js-keeper-editor {
    resize: vertical;
  }
}

.social-box-btn-workplace:hover {
  background-color: #373e4c;
}

.social-box-btn-dailymotion:hover {
  background-color: #00dcfe;

  svg path.cls-22 {
    fill: #00dcfe !important;
  }
}

.social-box-btn-yammer path {
  fill: #0072c6;
}

.social-box-btn-yammer:hover {
  background-color: #0072c6;
}

.facebook-preview .fa-workplace, .fa-yammer {
  float: right;
  text-align: center;
  font-size: 27px;
}

/**** yammer Preview ****/

.yammer-preview {
  -moz-box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
  -webkit-box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-bottom: 15px;
  padding: 15px;
}

.yammer-preview {

  .user-img {
    border-radius: 50px;
    float: left;
    margin-right: 10px;
    width: 40px;
  }

  .infos-user {
    .username, .posted-date {
      font-size: 14px;
      line-height: 20px;
    }

    .username {
      color: #3b5998;
    }

    .posted-date {
      color: #90949c;
      display: inline-block;
      font-size: 14px;
      margin-left: 5px;
    }

    .icon-earth {
      display: inline-block;
      vertical-align: sub;
      width: 15px;
    }
  }

  .username {
    color: #ccc;
  }

  .content-text {
    border-bottom: 0 solid #ccc;
    font-size: 14px;
    margin: 0;
    padding: 10px 0;
    word-wrap: break-word;

    .hashtag,
    .user,
    .link {
      color: #3b5998;
    }
  }

  .icon-like {
    margin: 10px 5px 10px 0;
    width: 20px;
  }

  .like-counter {
    color: #ccc;
    font-size: 14px;
  }

  .bar {
    margin: 0;
    padding-top: 10px;
  }

  .yammer-bar {
    height: 35px;
  }

  .shared-item {
    -moz-box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);

    &.js-preview-card {
      width: 50%;
    }

    .metas {
      padding: 10px 12px;

      .meta-description {
        font-size: 12px;
      }
    }

    .item-domain {
      color: #90949c;
      display: block;
      font-size: .7em;
      margin-top: 10px;
      text-transform: uppercase;
    }
  }

  .meta-title {
    color: #2760d1;
    font-family: inherit, Lucida Grande, Tahoma, Verdana, Arial, sans-serif;
    font-size: 15px;
    font-weight: normal;
    line-height: 22px;
    margin-top: 0;
  }
}

.timeline .icon {
  .fa-stack-1x {
    line-height: 2.5em !important;
  }

  &.workplace .fa-circle {
    color: #373e4c;
  }

  &.yammer .fa-circle {
    color: #0072c6;
  }

  &.linkedin .fa-circle {
    color: #0077b5;
  }

  .wizicon,
  svg.wizicon path {
    fill: #fff;
  }

  // Hack for facebook icon, remove out-filled rectangle
  svg.wizicon-social-icon-facebook {
    // Hide the blue rectangle
    .inside-svg-1 {
      fill: rgba(0, 0, 0, 0) !important;
    } // Fill the 'In'
    .inside-svg-2 {
      fill: #fff !important;
    }
  }

  // Hack for linked icon, remove out-filled rectangle
  svg.wizicon-social-icon-linkedin {
    // Hide the blue rectangle
    .st0 {
      fill: rgba(0,0,0,0) !important;
    }

    // Fill the 'In'
    .st1 {
      fill: #fff !important;
    }
  }
}

// Workplace account creation
#btn-test-token i.check-cta-icon {
  position: relative;
  top: 3px;
}

#social_account_workplace_type_access_token {
  resize: none;
}

#sms-sender {
  button.close {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .modal-body {
    .section-modal {
      margin: 0 0 40px 0;
    }

    span.input-group-addon {
      padding: 6px;

      .wizicon-french-flag {
        vertical-align: middle;
      }
    }

    .input-group input[type="text"] {
      border-right: inherit;
    }

    .input-group {
      input.form-control {
        border-right: 1px solid #e4eaec;

        &:focus {
          border-right-color: #66afe9;
        }
      }
    }

    form {
      .form-title {
        font-size: 24px;
        font-weight: 500;
      }

      label,
      .form-group {
        margin-bottom: 0;
      }

      .field-info {
        margin-left: 25px;
      }

      #send-sms-to-receiver {
        display: block;
        margin: 30px auto;
        width: 50%!important;
      }
    }

    .msg-infos {
      margin-top: 20px;
    }
  }
}

// LinkedIn companies page icon
.social-linkedin-page-icon {
  background: #b3b6b9;
  display: block;
  height: 100px;
  margin: 0 auto;
  overflow: hidden;
  width: 100px;
}

.social-account-icon {
  &.linkedin {
    background: #b3b6b9;
  }
}
