@mixin nav-huer(
    $link-color,
    $link-color-hover,
    $link-bg,
    $link-bg-hover,
    $link-color-active,
    $link-bg-active,
    $link-color-disabled,
    $link-bg-disabled
  ) {
  > .nav-item > .nav-link,
  > .nav-item > .dropdown-toggle, /* Override Bootstrap Dropdown */
  .open > a /* Override Bootstrap Dropdown */ {
    @if ('' != $link-bg and transparent != $link-bg and none != $link-bg) {
      background-color: $link-bg;
    }

    color: $link-color;

    &:hover,
    &:focus {
      color: $link-color-hover;
      background-color: $link-bg-hover;
    }
  }

  > .nav-item > .nav-link.offie--active {
    color: $link-color-active;
    background-color: $link-bg-active;
  }

  .active > .nav-link {
    &,
    &:hover,
    &:focus {
      color: $link-color-active;
      background-color: $link-bg-active;
    }
  }

  .disabled > .nav-link {
    &,
    &:hover,
    &:focus {
      color: $link-color-disabled;
      background-color: $link-bg-disabled;
    }
  }
}

@mixin make-nav-icon-variant(
    $icon-size,
    $icon-color,
    $icon-placeholder-color: #fff,
    $icon-bg-color: '',
    $icon-padding: 0,
    $icon-border-radius: 0
  ) {
  display: inline-block;

  @if $icon-bg-color != '' {
    background-color: $icon-bg-color;
  }

  @if $icon-border-radius != 0 {
    border-radius: $icon-border-radius;
  }

  @if $icon-padding != 0 {
    padding: $icon-padding;
  }

  svg {
    display: block;
    fill: $icon-color;
    height: $icon-size;
    vertical-align: middle;
    width:  $icon-size;
  }

  .smoothie-placeholder {
    fill: $icon-placeholder-color;
  }
}

@mixin make-navbar-variant(
    $navbar-bg,
    $navbar-brand-bg,
    $navbar-border-color,
    $navbar-shadow,
    $navbar-knob-bg,
    $navbar-knob-color
  ) {
  background-color: $navbar-bg;
  box-shadow: $navbar-shadow;

  .navbar-header,
  .navbar-brand,
  .navbar-knob {
    background-color: $navbar-brand-bg;
  }

  .nav-link {
    border-color: $navbar-border-color;
  }

  .navbar-knob-icon {
    fill: $navbar-knob-color;
  }
}
