@mixin make-card(
  $border,
  $border-radius,
  $box-shadow
) {
  border: $border;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}

@mixin make-profile-img($background-1, $background-2, $border-color, $font-color, $size, $padding, $font-size, $selector) {
  $border-width: 1px;

  background: $background-1;
  border-radius: 50%;
  border: $border-width solid $border-color;
  padding: $padding;
  height: $size;
  width: $size;

  #{$selector} {
    background-color: $background-2;
    border-radius: 50%;
    color: $font-color;
    line-height: $size - ($padding * 2) - $border-width * 2;
    display: block;
    font-size: $font-size;
    text-align: center;
    text-transform: uppercase;
  }
}
