.pane {
  border-right: 1px solid $border-color;
  height: 100vh;
  max-height: 700px;
  overflow: auto;
}

.pane-header {
  @include clearfix();

  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 2;
}

.pane-header-nav--2 {
  float: right;

  .pagination {
    margin: 0;
  }
}

.scrollingbar-red, // @TODO: backward-compatibility
.pane-content {
  &::-webkit-scrollbar {
    width: 5px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: #be1922;
  }
}
