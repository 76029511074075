.sp-cancel {
  display: none;
}

.sp-picker-container {
  width: 98px !important;
}

.sp-container {
  background-color: white !important;
  border-color: #e4e9ec !important;
  border-radius: 6px !important;
}

.sp-replacer:hover, .sp-replacer.sp-active {
  border-color: #e4e9ec !important;
}

.sp-replacer {
  border-color: #e4e9ec !important;
  background-color: white !important;
  display: block !important; /* !important seems required, spectrum.css loaded after? */
  width: 125px;
  height: 50px;
  border-radius: 5px !important;
}

.sp-preview {
  border: none !important;
  margin-top: 5px !important;
  margin-left: 5px;
  background-image: none !important;
}

.sp-preview-inner {
  border-radius: 3px;
  width: 80px;
  height: 30px;
}

.sp-dd {
  margin-left: 64px !important;
  margin-top: 10px;
}

.sp-clear-enabled .sp-clear {
  display: none !important
}

.sp-clear-enabled .sp-hue {
  top: 2px !important;
  height: 96% !important;
}

.sp-clear-display {
  background-image: none !important;
}

.sp-input-container.sp-cf {
  width: 80%;
  float: left;
}

.sp-initial-disabled .sp-input-container {
  width: 75% !important;
}

.sp-container button {
  background-image: none !important;
  background-color: #FFFFFF !important;
  border: 1px solid #CECACA !important;
  padding: 5px 2px !important;
  font-size: 12px !important;
}
