.layout {
  height: 100%;
  padding-top: $navbar-app-header-height;
  width: 100%;
}

.layout-wrapper {
  position: relative; /* trick to get the sidenav 100% height. */
}

.layout-header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1030;

  .navbar-knob {
    float: left;
    width: $navbar-app-header-height;
  }

  .butterbar {
    left: 0;
    right: 0;
    width: auto;
  }
}

.layout-content {
  min-height: 100%;
  padding: 20px 0 60px 0;
}

.layout-aside--left {
  box-shadow: 0 2px 8px 0 rgba(#000, 0.1);
  position: relative;

  .offie-layer {
    background-color: transparent; // Resets .offie-layer
    border: 0;
  }

  .section--aside {
    .section-text {
      color: $aside-left-item-color;
    }
  }
}

@media screen and (min-width: 768px) {
  .layout {
    display: table;
    height: 100%; // Needed
    width: 100%;
  }

  .layout-wrapper {
    display: table-row; // Needed
  }

  .layout-aside,
  .layout-content {
    display: table-cell;
    height: 100%;
    vertical-align: top; // Needed since chrome v76.
    width: 100%;
  }

  .layout-content {
    // https://stackoverflow.com/questions/26292408/why-does-this-behave-the-way-it-does-with-max-width-0
    max-width: 1px;
  }

  .layout-aside--left {
    width: $aside-left-width;
    z-index: 1021;

    .offie-layer {
      height: auto;
      overflow-x: hidden;
      overflow-y: visible;
      position: relative;
      transform: translateX(0);
      visibility: visible;
      z-index: 1021;
    }

    .offie-layer.aside-2 {
      overflow-x: visible;
    }
  }

  .layout-aside--left .section--aside {
    padding-top: 20px;
  }

  .layout-aside--left .section-header,
  .layout-aside--left .section-block--user {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .layout-aside--left,
  .layout--compact .layout-aside--left {
    .offie-layer {
      transition-duration: 0.15s, 0.25s;
      transition-timing-function: linear;

      &.offie--active {
        width: $aside-left-width;

        .nav--lvl1 > .nav-item {
          .nav-item-text,
          .nav-item-icon--collapse {
            visibility: visible;
          }
        }
      }
    }

    .layout-content {
      padding-left: 60px;
    }

    .nav--lvl1 {
      overflow: hidden;
      width: $aside-left-width;
    }

    .nav--lvl1 > .nav-item {
      .nav-item-text,
      .nav-item-icon--collapse {
        transition: visibility 0s;
        visibility: hidden;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .layout-aside--left {
    .active .offie--active {
      height: auto; // small fix to not have a flashing / opening menu on load.
    }
  }
}

@include make-layout-variant(
  $navbar-app-bg-color,
  $navbar-app-brand-bg-color,
  $aside-left-bg-color,
  $content-bg-color,
  $backdrop-bg-color,
  $backdrop-opacity
);

.navbar--app {
  @include make-navbar-variant(
    $navbar-app-bg-color,       // $navbar-bg,
    $navbar-app-brand-bg-color, // $navbar-brand-bg,
    $navbar-app-border,         // $navbar-border,
    $navbar-app-shadow,         // $navbar-shadow,
    $navbar-app-knob-bg,        // $navbar-knob-bg,
    $navbar-app-knob-color      // $navbar-knob-color
  );

  .navbar-brand {
    svg {
      fill: #fff;
    }
  }

  .navbar-nav {
    @include nav-huer(
      $navbar-app-item-color,             // $link-color,
      $navbar-app-item-color-hover,       // $link-color-hover,
      transparent,                        // $link-bg,
      $navbar-app-item-hover-bg,          // $link-bg-hover,
      $navbar-app-item-text-color-active, // $link-color-active,
      $navbar-app-item-hover-bg,          // $link-bg-active,
      #fff,                               // $link-color-disabled,
      transparent                         // $link-bg-disabled
    );

    .nav-link-icon--primary {
      @include make-nav-icon-variant(
        $navbar-app-icon-size,       // $icon-size,
        $navbar-app-icon-color,      // $icon-color,
        $navbar-app-icon-placeholder // $icon-placeholder-color: #fff,
      );
    }

    .nav-link-icon--secondary {
      @include make-nav-icon-variant(
        15px,                        // $icon-size,
        $navbar-app-icon-color,      // $icon-color,
        $navbar-app-icon-placeholder // $icon-placeholder-color: #fff,
      );
      height: 16px; // fix for weird icon.
      padding: 10px 0;
    }
  }
}

@media screen and (min-width: 768px) {
  .layout-content .container-fluid {
    padding: 0 30px;
  }

  .container,
  .container-fluid {
    .navbar-knob {
      margin-left: -15px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  @include make-layout($aside-left-width-collapsed);

  .offie-container.offie--active {
    overflow: visible;
  }

  .layout-header {
    .navbar-brand {
      display: none;
    }
  }
}

@media screen and (min-width: 992px) {
  @include make-layout($aside-left-width);

  .layout-header {
    .navbar-knob {
      display: none;
    }

    .navbar-brand-icon {
      display: none;
    }
  }

  .layout-aside--left {
    .active .offie--active {
      height: auto; // small fix to not have a flashing / opening menu on load.
    }
  }
}

.layout-content-inner {
  @include clearfix();
}

.layout-content-panel {
  float: left;

  .section--2k17 {
    border-bottom: 1px solid $border-color;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

.layout-content-panel--main {
  border-right: 1px solid $border-color;
  padding-right: 30px;
  width: (100% * .666666);
}

.layout-content-panel--aside {
  background-color: $brand-light;
  padding: 20px 30px;
  width: (100% * .333333);
}
