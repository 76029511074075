.btn--2k17 {
  border-radius: 2px;
  color: $link-color;
  font-weight: 600;
  letter-spacing: 0.065em;
  padding: 12px 15px;
  transition: background-color 0.23s, color 0.3s;
  white-space: nowrap;

  .btn-icon {
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;

    .wizicon {
      display: block;
      height: 12px;
      transition: fill 0.25s;
      width: 12px;
    }
  }

  .btn-text {
    display: inline-block;
    transition: color 0.25s;
    vertical-align: middle;
  }
}

.btn--primary-danger {
  @include make-button-variant($brand-primary, $btn-text-over-brand-primary, darken($brand-primary, 5%), $btn-text-over-brand-primary);
}

.btn--secondary-default {
  @include make-button-variant(#ffffff, $text-color, #dadada, $text-color);
}

button {
  padding: 0;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.wiz-btn {
  display: inline-flex;
  font-size: 13px;
  font-weight: 600;
  line-height: 13px;
  align-items: center;
  padding: 12px 13px;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;

  .badge {
    color: #fff;
    background-color: #337ab7;
    margin-left: 5px;
  }

  .wizicon {
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }

  &:hover:not(.disabled) {
   transition: all 0.2s ease-in-out;
  }
  &.center {
    justify-content: center;
  }

  &.btn-icon {
    position: relative;
    padding-left: 37px;
  }

  &.btn-icon [class*="fa fa-"] {
    position: absolute;
    left: 0;
    padding: 0 10px;
    line-height: 2.50em;
  }

  &.wiz-btn--add {
      &:hover:not(.disabled) {
      box-shadow: 0 5px 15px rgba(0, 196, 70, .3);
    }
  }
}

.wiz-btn-primary {
  color: #fff;

  &:hover {
    color: white;
  }

  &.wiz-btn--add {
    background: linear-gradient(35deg, $green, $light-green);
  }

  &.wiz-btn--save {
    background: linear-gradient(35deg, $blue, $light-blue);
    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px rgba(44, 108, 215, .3);
    }
  }

  &.wiz-btn--delete, &.wiz-btn--danger {
    background-color: $red;
    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px rgba(190,25,34, .3);
    }
  }

  &.wiz-btn--confirm {
    background: linear-gradient(35deg, $green, $light-green);
    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px rgba(0, 196, 70, .3);
    }
  }

  &.wiz-btn--other {
    background: linear-gradient(35deg, $blue, $light-blue);
    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px rgba(44, 108, 215, .3);
    }
  }


  &.disabled, &:disabled {
    background-image: none;
    background-color: $grey;
    cursor: not-allowed;
    user-select: none;
  }

  .wizicon {
    fill: #fff;
  }
}

.wiz-btn-secondary {
  background: none;

  &.wiz-btn--add {
    color: $green;
    border: 1px solid $green;

    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px rgba(0, 196, 70, .3);
    }

    .wizicon {
      fill: $green;
    }
  }

  &.wiz-btn--save {
    color: $blue;
    border:1px solid $blue;
    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px rgba(44, 108, 215, .3);
    }

    .wizicon {
      fill: $blue;
    }
  }

  &.wiz-btn--delete, &.wiz-btn--danger {
    color: $red;
    border: 1px solid $red;
    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px rgba(190,25,34, .3);;
    }

    .wizicon {
      fill: $red;
    }
  }

  &.wiz-btn--upload {
    color: $blue;
    border: 1px solid $blue;
    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px rgba(44, 108, 215, .3);
    }

    .wizicon {
      fill: $blue;
    }
  }

  &.wiz-btn--confirm {
    color: $green;
    border: 1px solid $green;
    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px rgba(0, 196, 70, .3);
    }

    .wizicon {
      fill: $green;
    }
  }

  &.wiz-btn--cancel {
    color: $blue;
    border: 1px solid $blue;
    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px rgba(44, 108, 215, .3);
    }

    .wizicon {
      fill: $blue;
    }
  }

  &.wiz-btn--other {
    color: $blue;
    border: 1px solid $blue;
    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px rgba(44, 108, 215, .3);
    }

    .wizicon {
      fill: $blue;
    }
  }

  &.wiz-btn--workplace {
    color: #373e4c;
    border: 1px solid #373e4c;

    .wizicon {
      fill: #373e4c;
    }

    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px #373e4c;
    }
  }

  &.wiz-btn--yammer {
    color: #265495;
    border: 1px solid #265495;

    .wizicon {
      fill: #265495;
    }

    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px #265495;
    }
  }

  &.wiz-btn--twitter {
    color: #1da1f2;
    border: 1px solid #1da1f2;

    .wizicon {
      fill: #1da1f2;
    }

    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px #1da1f2;
    }
  }

  &.wiz-btn--linkedin {
    color: #2867b1;
    border: 1px solid #2867b1;

    .wizicon {
      fill: #2867b1;
    }

    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px #2867b1;
    }
  }

  &.wiz-btn--facebook {
    color: #1877f2;
    border: 1px solid #1877f2;

    .wizicon {
      fill: #1877f2;
    }

    &:hover:not(.disabled) {
      box-shadow: 0 5px 15px #1877f2;
    }
  }

  &.disabled, &:disabled {
    color: $grey;
    border-color: $grey;
    cursor: not-allowed;
    user-select: none;

    .wizicon {
      fill: $grey;
    }
  }
}

.wiz-btn-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.upload-row {
  display: flex;
  justify-content: center;
}

.wiz-btn-grouped {
  display: flex;
  align-items: center;

  .wiz-btn {
    border: 1px solid $blue;
    &:not(:first-child) {
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.active {
      color: #fff;
      background-color: $blue;
      a {
        color: #fff;
      }
    }
    &:not(.active) {
      background: white;
      border-color: $blue;
    }
  }
}

@media screen and (min-width: 480px) {
  .new-media-review {
    float: right;
  }
}
