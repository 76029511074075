.folder-files--list {
  display: flex;
  flex-flow: row wrap;
}

.folder-file--item {
  min-width: 0;
  flex: 0 0 16.6666666667%;
  padding: 10px;
  cursor: move;

  .file-container--image {
    position: relative;

    span {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }

  img {
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
  }

  .file-item--title {
    display: flex;
    align-items: center;
  }

  h4 {
    font-size: 13px;
    margin: 0;
  }

  .folder-file--status {
    display: block;
    width: 13px;
    height: 13px;
    flex: 0 0 auto;
    margin-right: 10px;
    border: 2px solid;
    border-radius: 50%;

    &.folder-file--online {
      border-color: green;
    }

    &.folder-file--offline {
      border-color: red;
    }
  }

  .folder-item--content {
    background-color: #fff;
    border: 1px solid #eee;
  }

  .file-container--content {
    padding: 10px 15px;
    border-top: 1px solid #eee;
  }
}
