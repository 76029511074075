@mixin make-title-2() {
  color: $text-color;
  font-size: $font-size-lg;
  font-weight: 400;
}

@mixin make-title-3() {
  color: $text-color;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.035em;
}

@mixin make-title-4() {
  color: $text-color;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.035em;
}

@mixin make-title-5() {
  color: $text-color;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

@mixin make-text-secondary() {
  color: $text-muted;
}

@mixin make-link() {
  color: $brand-info;

  &:focus,
  &:hover {
    color: desaturate(darken($brand-info, 15%), 10%);
  }
}

@mixin link-action() {
  color: $brand-info;
  font-size: $font-size-sm;
  font-weight: 600;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  white-space: nowrap;

  &:focus,
  &:hover {
    color: desaturate(darken($brand-info, 15%), 10%);
  }
}

@mixin link-action--danger() {
  color: $brand-danger;

  &:focus,
  &:hover {
    color: desaturate(darken($brand-danger, 15%), 10%);
  }
}

@mixin link-default() {
  &,
  &:focus,
  &:hover {
    color: inherit;
  }
}

@mixin link-reset() {
  &,
  &:focus,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

@mixin truncate-text() {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
