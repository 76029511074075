.label--channel {
  color: $text-muted;
  margin-right: 7px;
  padding: 0;

  > .wizicon {
    display: inline-block;
    height: 10px;
    width: 10px;
  }
}

.label--2k18 {
  &.label--default {
    border-radius: 2px;
    background-color: rgba($brand-secondary, .07);
    color: $brand-secondary;
    font-size: 75%;
  }
}

.label--rp-connect {
  display: inline-block;
  margin-left: 15px;
  padding: 2px 10px;
  vertical-align: middle;
  > img {
    max-width: 60px;
  }
}
