.card-img {
  &,
  img {
    max-width: 100%;
  }
}

.card-title {
  > a {
    color: $text-color;
    text-decoration: none;
  }
}

.card-block {
  position: relative;
}

.card-block {
  > *:last-child,
  > *:last-child > *:last-child {
    margin-bottom: 0;
  }
}

.card--2k17 {
  display: block;
  margin-bottom: 0;
}

@mixin make-card(
  $border,
  $border-radius,
  $box-shadow
) {
  border: $border;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}

.card--channel {
  @include make-card(
    $card-channel-border,        // $border
    $card-channel-border-radius, // $border-radius
    $card-channel-box-shadow     // $box-shadow
  );
  @include animate-hover-1();

  cursor: default;
  opacity: 0.5;
  pointer-events: none;
  position: relative;

  &[href] {
    cursor: pointer;
    pointer-events: all;
    opacity: 1;
  }

  .card-img {
    background-color: $brand-primary;
    border-bottom: 5px solid $border-color;
    border-top-left-radius: $card-channel-border-radius;
    border-top-right-radius: $card-channel-border-radius;
    padding: 20px;

    svg {
      fill: #fff;
      padding: 10px 15px;
    }
  }

  .card-img-matter {
    display: block;
    height: 50px;
    margin: auto;
    width: 200px;
  }

  .card-decorator {
    background-color: $brand-primary;
    border-radius: $card-channel-decorator-height;
    bottom: 20px;
    height: $card-channel-decorator-height;
    left: 15px;
    position: absolute;
    width: $card-channel-decorator-width;
  }

  .card-block {
    overflow: hidden;
    padding-bottom: 40px; // Space for the .card-btn + .card-decorator;
  }

  .card-title {
    @include make-title-5();

    margin-bottom: $card-channel-spacer-bottom;
  }

  .card-text,
  .card-title-modifier {
    color: $brand-secondary;
    font-size: 12px;
    font-style: italic;
    text-transform: none;
  }

  .card-title-modifier {
    color: $brand-info;
  }

  .card-btn {
    @include link-action();
    bottom: 15px;
    float: right;
    position: absolute;
    right: 15px;
  }
}

.card--outlink {
  @include make-card(
    $card-channel-border,        // $border
    $card-channel-border-radius, // $border-radius
    $card-channel-box-shadow     // $box-shadow
  );
  @include animate-hover-1();

  position: relative;

  .card-block {
    margin-right: 65px;
  }

  .card-title {
    @include make-title-5();
  }

  .card-subtitle {
    @include truncate-text();
    font-size: $font-size-sm;
  }

  .card-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;

    .card-img-matter {
      @include size(25px, 25px);
      opacity: 0.2;
      transition: opacity 0.3s;
    }
  }

  &[href]:hover,
  &[href]:focus {
    .card-img-matter {
      opacity: 0.4;
    }
  }
}

.card--contact {
  .card-img {
    margin: 0 auto 15px;

    img {
      background-color: $brand-primary;
      border-radius: 50%;
      margin: auto;
      padding: 2px;
    }
  }

  .card-img-placeholder {
    font-size: 40px;
  }

  .card-title {
    font-weight: bold;
    text-align: center;
  }

  .card-block {
    padding: 0;
  }
}

.card--contactlist {
  .card-title {
    font-weight: bold;
    text-align: center;
  }

  .card-img,
  .card-img-placeholder {
    margin: 0 auto 15px;
    height: 100px;
    width: 100px;

    &,
    img {
      border-radius: 50%;
    }
  }

  .card-img-placeholder {
    border: 2px solid $border-color;
    color: $brand-primary;
    display: block;
    font-size: 40px;
    font-weight: 600;
    line-height: 1;
    padding: 25px 10px;
    text-align: center;
    text-transform: uppercase;
  }

  .card-block {
    padding: 0;
  }

  .card-footer {
    text-align: center;
  }

  &.card--guestlist {
    background-color: $brand-light;
    border: 2px solid $brand-light-dk;
    cursor: pointer;

    .card-img {
      display: none;
    }

    .card-block {
      padding-bottom: 35px;
      padding-top: 35px;
    }
  }
}

.card--contact-alt,
.card--contactlist-alt {
  @include clearfix();
  align-items: center;
  display: flex;

  .card-img,
  .card-img-placeholder {
    margin: 0;
    height: 50px;
    width: 50px;
  }

  .card-img-placeholder {
    font-size: 1em;
    padding: 32% 5%;
  }

  .card-title {
    text-align: left;
  }

  .card-block {
    flex-grow: 4;
    padding: 10px 25px;
  }

  .card-footer {
    // align-self: flex-end;
    // float: right;
  }
}

.card--setting {
  border: 1px solid $brand-light;
  border-radius: 2px;
  box-shadow: 0 2px 3px 0 rgba(#000, 0.04);
  transition: box-shadow 0.2s;
  transition-timing-function: cubic-bezier(0.34, 1, 0.34, 2);

  &:hover {
    box-shadow: 0 0px 0px 4px rgba(0, 0, 0, 0.04);
  }

  .card-title {
    float: left;
    margin-bottom: 0;

    .card-title-text {
      background-color: rgba(#000, 0.05);
      border-radius: 4px;
      color: #000;
      font-family: monospace;
      font-size: $font-size-base;
      font-weight: 700;
      padding: 4px 8px;
    }
  }

  .card-text {
    line-height: 22px;
    overflow: hidden;
    padding: 0 15px;

    [href] {
      @include link-reset();
    }
  }

  .card-btn {
    @include link-action();
    float: right;
  }
}

.card--user {
  padding: 15px;

  .card-img {
    @include make-profile-img($brand-primary, #fff, $border-color, $brand-primary, 35px, 1px, 15px, '.card-img-placeholder');
    float: left;
  }

  .card-block {
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
  }

  .card-title,
  .card-subtitle {
    margin-bottom: 5px;
  }

  .card-footer {
    margin-top: 10px;
  }
}

.card--workplace {
  border: 1px solid $workplace-border;

  .card-block {
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;

    .card-description {
      columns: 2;
      display: block;
      list-style-type: none;
      padding: 10px;

      .card-permission {
        display: inline-block;
        margin-left: 23px;
        padding-bottom: 15px;
        vertical-align: baseline;

        .card-permission-icon {
          margin: 3px 0 0 -23px;
          padding: 0;
          position: absolute;
        }

        .card-permission-icon--optional {
          margin: 3px 0 0 -23px;
          padding: 0;
          position: absolute;

          svg.wizicon-check-icon {
            .cls-1 {
              fill: $brand-warning !important;
            }
          }
        }

        .card-permission-title {
          color: $workplace-title;
          font-size: 12px;
          font-weight: bold;
          line-height: 18px;
          vertical-align: middle;
        }

        .card-permission-description {
          color: $workplace-description;
          line-height: 16px;
        }
      }
    }

    .card-profile {
      border-radius: 3px;
      border: 1px solid $workplace-border;
      box-sizing: border-box;
      display: flex;
      margin: 12px auto 24px auto;
      padding: 12px;
      width: 50%;

      .card-profile-picture {
        display: inline-block;
        height: 50px;
        width: 50px;
      }

      .card-profile-block {
        display: inline-block;
        margin-left: 12px;

        .card-profile-name {
          font-size: 14px;
          font-weight: bold;
          line-height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .card-profile-description {
          color: $workplace-description;
          font-size: 12px;
          line-height: 14px;
          margin-top: 4px;
        }
      }
    }
  }

  .card-title {
    background: $workplace-background;
    color: $workplace-title;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 20px;
    padding: 16px 24px;
  }
}

.wizicon-finger-print {
  height: 13px;
}

.wizicon-finger-print-success {
  height: 24px;
  fill: #6ccf5f;
  vertical-align: middle;
  width: 18px;
}

.wizicon-finger-print-info {
  height: 24px;
  fill: #497fdc;
  vertical-align: middle;
  width: 18px;
}

.wizicon-finger-print-button {
  height: 24px;
  fill: #497fdc;
  vertical-align: middle;
  width: 18px;
}

.file-index-icon {
  font-size: 13px;
  margin-right: 3px;
}

.blockchain-status--certified {
  display: inline;
}

.blockchain-status--not-certified {
  display: none;
}

.blockchain-status--pending {
  display: none;
}
