.gallery--grid {
  .gallery-item {
    margin-bottom: 15px;
  }
}

.gallery--list {
  @include reset-list-style();

  .gallery-item {
    margin-bottom: 20px;
  }
}

.list--userwidget {
  @include reset-list-style();
  text-align: right;

  .list-item + .list-item {
    margin-top: 10px;
  }

  .list-item--language {
    .property-value {
      text-transform: uppercase;
    }
  }

  .list-item--logout {
    border-top: 1px solid $brand-light-dk;
    padding-top: 10px;
  }

  .property-key {
    color: $text-muted;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
  }

  .link {
    @include link-action();
  }
}

.list--attributes {
  @include reset-list-style();

  .list-item {
    .wizicon {
      fill: $brand-secondary-lt;
      margin-right: 5px;
    }
  }
}

.list--channelswitch {
  list-style: none;
  padding-left: 0;

  > .list-item {
    margin-bottom: 25px;
  }
}

.list--channelmonitoring {
  @include reset-list-style();
  @include clearfix();

  .list-item {
    border-bottom: 1px dashed $border-color;
    padding: 3px 0;
  }

  .property-key {
    color: $text-muted;
    float: left;
    font-size: $font-size-sm;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
  }

  .property-val {
    overflow: hidden;
    text-align: right;
  }
}

.list--alerts {
  @include reset-list-style();

  .list-item {
    margin-bottom: 10px;
  }
}

.property {
  @include reset-list-style();
}

.list--form-main,
.list--form-aside {
  @include reset-list-style();
  @include clearfix();

  .list-item {
    border-bottom: 1px solid $brand-light-dk;
    min-height: 51px;
  }

  .form-group {
    margin-bottom: 0;
    padding: 7.5px 0;
  }

  .form-group--toggle {
    padding: 0;
  }

  .form-group--toggle label, // For toggles to kick in.
  .property-key,
  .property-val {
    line-height: 50px;
  }

  label, // form-label
  .property-key {
    color: $text-muted;
    font-size: $font-size-sm;
    font-weight: 600;
    text-transform: uppercase;
  }

  label {
    padding: 0;
  }

  .property-key {
    float: left;
  }

  .property-val {
    overflow: hidden;
    text-align: right;
  }
}
