.navbar {
  border-radius: 0;
  border-width: 0;
  margin-bottom: 0; // Resets Bootstrap.
}

.navbar-brand {
  display: block;
  float: none;
  padding: 0;
  position: relative;

  .navbar-brand-img {
    display: block; // Overrides common/style.css
    height: auto; // Overrides common/style.css
    margin: auto;
    max-width: 100%;
  }

  &:after {
    @include draw-pseudo();
    background-color: $brand-primary;
    bottom: -4px;
    height: 4px;
    position: absolute;
    width: 100%;
  }

  svg {
    display: block;
    height: 40px;
    margin: auto;
    padding: 10px 15px;
    width: $navbar-app-header-width;
  }
}

@media screen and (max-width: 767px) {
  .navbar-brand {
    width: 200px;

    &:after {
      display: none;
    }
  }

  .navbar-nav {
    margin: 0;
  }
}

.navbar-nav--channel {
  float: none;
  overflow: hidden;

  .nav-item--channeltitle {
    float: none;
    overflow: hidden;
  }
}

///
.navbar--app {
  .navbar-nav > .nav-item > .nav-link,
  .open .nav-link {
    height: $navbar-app-header-height;
    white-space: nowrap; // For IE
  }

  .nav-link-icon {
    display: block;

    .wizicon {
      opacity: 0.75;
    }
  }
}

///
.nav-item--user {
  > .nav-link {
    line-height: inherit;
    padding: $navbar-user-spacer-y $navbar-user-spacer-x;
  }

  .navbar-user-img {
    float: left;
    height: $navbar-user-img-size;
    width: $navbar-user-img-size;

    > img {
      max-width: 100%;
    }
  }

  .navbar-user-body {
    overflow: hidden;
    padding: 0 $navbar-user-spacer-x;
  }

  .nav-link-text {
    display: block;
  }

  .nav-link-icon {
    float: right;
  }

  .navbar-user-img {
    @include make-profile-img($brand-primary, #fff, $brand-primary, $brand-primary, 35px, 0px, 15px, '.navbar-user-img-placeholder');
  }
}

.navbar--app {
  .navbar-knob {
    display: block;
    padding: 15px 15px;

    .navbar-knob-icon {
      display: block;
      margin: auto;
      height: 20px;
      width: 20px;
    }
  }

  .nav-item--user {
    .nav-link-text {
      font-size: 12px;

      &.nav-link-text--primary {
        font-weight: 600;
      }

      &.nav-link-text--secondary {
        opacity: 0.66;
      }
    }
  }
}

.navbar--app {
  .nav-item--activity,
  .nav-item--channelswitch,
  .nav-item--channelgoto {
    > .nav-link {
      line-height: $navbar-app-icon-size;
      padding: calc(($navbar-app-header-height - $navbar-app-icon-size) / 2);
    }
  }

  .nav-item--channeltitle {
    .nav-link {
      font-size: 16px;
      font-weight: 600;
      text-align: center;

      &:focus,
      &:hover {
        background-color: transparent !important;
      }
    }

    .notify {
      margin-left: 12px;
    }
  }
}

.navbar-techbar {
  bottom: 0;
  left: 0;
  position: absolute;
  transform: translateY(66%);
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .navbar--app {
    .navbar-nav--channel {
      display: none;
    }

    .navbar-nav--user {
      display: none;
    }
  }
}
