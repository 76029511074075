$progress-bar-bg-color: transparent;
$progress-bar-color: $brand-primary;
$progress-bar-height: 4px;

.butterbar {
  position: absolute;
  height: $progress-bar-height;
  display: none;
  width: 100%;
  background-color: $progress-bar-bg-color;
  margin: 0;
  overflow: hidden;

  .determinate {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $progress-bar-color;
    transition: width .3s linear;
  }

  .bar {
    background-color: $progress-bar-color;

    &:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left:0;
      bottom: 0;
      will-change: left, right;
      // Custom bezier
      animation: indeterminate 2.1s cubic-bezier(0.650, 0.815, 0.735, 0.395) infinite;
    }

    &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left:0;
      bottom: 0;
      will-change: left, right;
      // Custom bezier
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite;
      animation-delay: 1.15s;
    }
  }

  &.active {
    display: block;
  }
}

@keyframes indeterminate {
    0% {
      left: -35%;
      right: 100%;
    }

    60% {
      left: 100%;
      right: -90%;
    }

    100% {
      left: 100%;
      right: -90%;
    }
}

@keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%;
    }

    60% {
      left: 107%;
      right: -8%;
    }

    100% {
      left: 107%;
      right: -8%;
    }
}
