.section-block,
.section-element,
.section-header,
.section-img,
.section-text {
  margin-bottom: $section-spacer-y;
}

.section-block,
.section-header,
.section-footer,
.section-wrapper {
  > *:last-child {
    margin-bottom: 0;
  }
}

.section--2k17 {
  margin-bottom: $section-spacer-y * 2;
  padding: 0; // Resets back/style.css

  .section-title {
    @include make-title-2();

    a {
      @include link-default();
    }
  }

  .section-title--secondary {
    @include make-title-3();
  }
}

.section--prime {
  margin-bottom: 25px;
  padding-bottom: 15px;
  padding-top: 15px;

  .section-aside--actions {
    float: right;
    margin-left: 15px;

    .section-aside-item {
      display: inline-block;
    }
  }

  .section-aside--back {
    float: left;
    margin-right: 15px;

    .section-aside-item {
      border-right: 1px solid $border-color;
      padding-right: $section-prime-space-x;
      line-height: 32px; // height of a button.
    }
  }

  .section-title {
    line-height: 32px; // height of a button.
    overflow: hidden;
  }
}

.section--offie {
  .section-header {
    border-bottom: 1px solid $brand-light-dk;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.04);
    margin-bottom: 0;

    .section-title {
      @include make-title-3();
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      line-height: 30px;
      overflow: hidden;
      padding: 10px 20px;
    }
  }

  .section-block {
    padding: 20px;
  }

  .section-title--secondary {
    @include make-title-4();
  }

  .section-aside {
    float: right;

    .offie-layer--secondary & {
      float: left;
    }
  }

  .section-aside-item {
    padding: 17px 15px;
    display: block;

    > .wizicon {
      display: block;
      fill: rgba($brand-secondary, 0.6);
      height: 18px;
      transition: fill 0.2s;
      width: 18px;
    }

    &:focus,
    &:hover {
      > .wizicon {
        fill: rgba($brand-secondary, 0.8);
      }
    }
  }

  .section-text {
    color: $text-muted;
  }

  .section-btn-text {
    font-size: $font-size-sm;
    font-weight: 600;
    letter-spacing: 0.035em;
    text-transform: uppercase;
  }
}

.section--boxy {
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: 4px;
  min-height: 20px;

  .section-wrapper {
    padding: 20px;
  }

  .section-header,
  .section-block {
    padding: 0;
  }

  .section-block {
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0px;
    }
  }
}

.section--drawer {
  margin-left: -31px;
  padding-top: 0; // Resets some old CSS.
}

.section--targets {
  .section-block {
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom-color: transparent;
    }
  }
}

.section--userprofile {
  .section-img {
    @include make-profile-img(#fff, #fcfcfc, $border-color, $brand-primary, 180px, 7.5px, 60px, '.section-img-placeholder');
    margin-left: auto;
    margin-right: auto;
  }
}

.section--aside {
  .section-header {
    @include clearfix();
    background-color: $section-aside-header-bg-color;
  }

  .section-aside--close {
    float: right;
  }

  .section-title {
    @include make-title-3();

    border-top: 1px solid $border-color;
    color: #fff;
    padding: 10px 15px;
    text-align: center;
  }

  .nav--channel {
    background-color: #fff;

    .nav-item {
      border-right: 1px solid $border-color;
      float: left;
    }
  }

  .nav--channel .nav-link,
  .section-aside--close .section-aside-item {
    display: block;
    padding: 15px 15px;
  }

  .nav--channel .wizicon,
  .section-aside--close .section-aside-item .wizicon {
    @include size(20px, 20px);
    display: block;
    fill: $brand-dark;
    opacity: 0.4;
  }

  .nav--channel .wizicon {
    opacity: 0.75;
  }

  .section-aside--close .section-aside-item {
    border-left: 1px solid $border-color;
  }

  .list--userwidget {
    background-color: #fff;
    padding: 15px;
  }

  .section-footer {
    svg {
      fill: $aside-left-icon-color;
      height: 50px;
      opacity: 0.4;
      padding: 10px 50px;
      width: 100%;
    }

    .section-text {
      font-size: $font-size-xs;
      text-align: center;
    }
  }
}

.live-state {
  visibility: hidden;

  .loader-label {
    left: 51%;
    position: absolute;
    top: 47%;
    transform: translate(-50%, -50%);
  }
}

.live-state.live-state--active {
  visibility: visible;
}

