.layout--2k17,
.layout--2k17a {
  .nav--main.nav--lvl1 {
    @include nav-huer(
      $aside-left-item-color,             // $link-color,
      $aside-left-item-color-hover,       // $link-color-hover,
      transparent,                        // $link-bg,
      $aside-left-item-hover-bg,          // $link-bg-hover,
      $aside-left-item-text-color-active, // $link-color-active,
      $aside-left-item-hover-bg,          // $link-bg-active,
      #fff,                               // $link-color-disabled,
      transparent                         // $link-bg-disabled
    );

    > .nav-item {
      margin-bottom: 2px;

      > .nav-link {
        font-family: $aside-left-font-family;
        font-size: $aside-left-font-size;
        font-weight: $aside-left-font-weight;
        letter-spacing: $aside-left-letter-spacing;
        padding: 15px 20px;
        text-transform: uppercase;

        &:before {
          background-color: transparent;
          bottom: 7px;
          content: '';
          display: block;
          left: 0;
          position: absolute;
          top: 7px;
          width: 4px;
        }
      }

      > .nav-link {
        &:focus,
        &:hover,
        &.offie--active {
          &:before {
            background-color: $aside-left-item-color-active;
          }
        }
      }

      &.active > .nav-link {
        &:before {
          background-color: $aside-left-item-color-active;
        }
      }
    }

    .offie-collapse.offie--active {
      background-color: $aside-left-item-hover-bg;
    }

    .nav-item-icon--primary {
      @include make-nav-icon-variant($aside-left-icon-size, $aside-left-icon-color, $aside-left-item-color);
      opacity: 0.66;
    }

    .nav-item-icon--collapse {
      opacity: 0.66;
    }
  }

  .nav--main.nav--lvl2 {
    @include nav-huer(
      $aside-left-item-color,             // $link-color,
      $aside-left-item-color-hover,       // $link-color-hover,
      transparent,                        // $link-bg,
      transparent,                        // $link-bg-hover,
      $aside-left-item-text-color-active, // $link-color-active,
      $aside-left-item-hover-bg,          // $link-bg-active,
      #fff,                               // $link-color-disabled,
      transparent                         // $link-bg-disabled
    );

    .nav-link {
      font-size: $aside-left-font-size * 0.95;
      letter-spacing: $aside-left-letter-spacing;
    }

    .nav-link,
    .active > .nav-link {
      background-color: transparent;

      &:focus,
      &:hover {
        background-color: transparent;
      }
    }

    .active .nav-link {
      color: $aside-left-item-color-hover;
      font-weight: 600;
    }
  }
}
