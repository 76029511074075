.timeline--2k18 {
  color : #777777;
  padding-left: 15px;

  a {
    font-weight: 700;
  }

  ol {
    @include reset-list-style();
  }

  .timeline-item-block {
    border: 1px solid $border-color;
    border-radius: 5px;
    margin-left: 40px;
    padding: 20px;
  }

  .timeline-item-data {
    font-size: $font-size-base;
    font-style: italic;
  }

  .timeline-item-icon-btn {
    float : right;
  }

  .timeline-item-published {
    margin-bottom: 10px;
  }

  .timeline-item-text {
    color: #333;
    font-size: $font-size-base;
    margin-bottom: 10px;
  }

  .timeline-item-title {
    font-size: $font-size-md;
  }
}

.card--stats {
  margin-bottom: 0;

  @media screen and (min-width: $grid-float-breakpoint) {
    max-width: 610px;
  }

  .card-block {
    padding: 0;
  }
}

.list--social-list {
  @include reset-list-style();
  @include clearfix();

  .list-item {
    background-color: $brand-light;
    border-bottom: 1px solid #cdd2d2;
    padding: 3px 0;

    &,
    &:last-child {
      padding :10px 20px;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      border-bottom: 0;
      border-right: 5px solid white;
      float: left;
      width: 33%;

      &:last-child {
        border-right: none;
      }
    }
  }

  .property-key {
    color: $text-muted;
    float: left;
    font-size: $font-size-base;
    line-height: 20px;

    .wizicon {
      color : $brand-dark;
      margin-right: 20px;
    }
  }

  .property-val {
    color: #333333;
    font-size: $font-size-base;
    font-weight: 600;
    overflow: hidden;
    text-align: right;
  }
}
