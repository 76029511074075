.library-selection--multiple {
  display: flex;
  align-items: center;

  .btn-group .btn {
    margin-right: 0;
    margin-bottom: 0;
  }

  .js-local-select-all, .js-global-select-all {
    font-size: 12px;
    color: #2C6CD7;
    background-color: transparent;
  }
}

.library-files-counter {
  visibility: hidden;
  margin-top: 20px;
  margin-bottom: 10px;

  &.active {
    visibility: visible;
  }
}

.new-card, .library-selection--multiple {
  .dropdown-menu > li > a {
    display: flex;
    align-items: center;
  }

  .bulkOption-icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;

    &.online {
      fill: #fff;
      stroke: #000;
    }
  }
}

.new-card {
  position: relative;
  width: 250px;
  border: 1px grey dotted;
  float: left;
  margin: 10px;

  .thumbnail {
    margin-bottom: 0;

    .overlay.overlay-tr-offset {
      left: 10px;
      bottom: 10px;
      top: auto;
      right: auto;
    }
  }

  .overlay-tick {
    right: 10px;
    top: 10px;

    i {
      font-size: 18px;
    }
  }

  .caption {
    padding: 10px 15px;
    border-top: 1px grey dotted;

    h2 {
      margin-bottom: 0;
    }

    .dropdown-toggle {
      cursor: pointer;
    }
  }

  .selected-indicator {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(9, 132, 227,0.3);
    top: 0;
  }

  &.new-card--selected {
    .selected-indicator {
      display: block;
    }
  }
}

.library-modal--qualification {
  h4 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
  }

  .qualification-modal--item {
    padding: 10px 0;

    p {
      font-size: 12px;
      margin-top: 10px;
    }
  }

  .qualification-modal--elements {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    span {
      display: block;
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }
}