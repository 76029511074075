.table--2k17 {
  border-bottom: 1px solid #dddddd;

  .table-header-element {
    border-bottom: none;
    font-size: $font-size-sm;
    font-weight: 600;
    letter-spacing: 0.015em;
    text-transform: uppercase;
  }

  .table-element {
    border-top: 1px solid #dddddd;
  }

  .table-item {
    .table-element:first-child {
      border-left: 1px solid #dddddd;
    }

    .table-element:last-child {
      border-right: 1px solid #dddddd;
    }

    &:focus,
    &:hover {
      background-color: $brand-light;

      .table-element {
        border-color: #3d70b2;
        border-bottom: 1px solid #3d70b2;
      }
    }
  }
}

.table-element:first-child {
  padding-left: 12px;
}

.table-element--datetime,
.table-element--number {
  text-align: right;
}

.table-element--icon {
  width: 60px;
}

.table-element-icon {
  margin-bottom: 10px;
  position: relative;
  width: 35px;

  svg {
    @include size(35px, 35px);

    fill: $brand-secondary;
  }

  &:after {
    background-color: $brand-danger;
    border-radius: 50%;
    bottom: 0;
    content: '';
    height: 15px;
    position: absolute;
    right: -7px;
    width: 15px;
  }

  &.table-element-icon--active {
    &:after {
      background-color: $brand-success;
    }
  }
}

.table-element-text {
  line-height: 1.25;

  &:last-child {
    margin-bottom: 0;
  }
}

.table-element-text--primary {
  font-weight: 600;
}

.table-element-text--secondary {
  color: $text-muted;
  font-size: $font-size-sm;
}
