.form--2k18 {
  label {
    color: $text-color;
    font-weight: 600;
  }
}

.form-group--sheeper {
  .sheep-link {
    float: right;
  }
}

.sheep--form-1 {
  .sheep-body {
    padding-bottom: 5px;
  }

  .sheep-nav {
    float: right;
    margin-right: -12px;

    .nav-link {
      padding: 18px 12px;

      .wizicon {
        height: 12px;
        width: 12px;
      }

      &.unsheep-link {
        .wizicon {
          fill: $brand-danger;
        }
      }
    }
  }

  .sheep-value {
    overflow: hidden;
  }
}

.wiz-checkbox {
  display: flex;
  align-items: center;

  input {
    display: none;
    &:checked {
      + .label-check {
         background: linear-gradient(to right, $blue, $light-blue);

        svg {
          display: block;
          fill: #fff;
        }
      }
    }
  }

  .label-check {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    border: 2px solid $med-blue;
    cursor: pointer;
    border-radius: 6px;

    .wiz-icon {
      display: none;
      width: 18px;
      height: 18px;
    }

    + label {
      font-size: 20px;
      color: #666666;
      margin-left: 15px;
    }
  }
}

.form--ajax-refreshing {
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .5);
  }
}