.toggle-light .toggle-slide {
  border: 1px solid #e4eaec;
  border-radius: 9999px;
    -webkit-box-shadow: none;
  box-shadow: none;
}

.toggle-light .toggle-blob,
.toggle-light .toggle-blob:hover {
  background: #696B70;
  box-shadow: none;
}

.toggle-light .toggle-on.active + .toggle-blob {
  background: #497fdc;
}

/*.toggle-light .toggle-on,
.toggle-light .toggle-select .toggle-inner .active {
  background-color: #497fdc;
  color: #fff;
}*/

.toggle-light .toggle-off,
.toggle-light .toggle-on,
.toggle-light .toggle-select .toggle-inner .active,
.toggle-light .toggle-select .toggle-on {
  background: #f6f7fd;
    -webkit-box-shadow: none;
  box-shadow: none;
  color: #696B70;
  text-shadow: none;
}

.toggle-off {
  width: 71px;
}

.toggle-light .toggle-on,
.toggle-light .toggle-off {
  font-size: 12px;
  text-transform: uppercase;
}

.toggle-light .toggle-on {
  padding-left: 30px;
  text-align: left;
}

.toggle-light .toggle-off {
  text-align: right;
  padding-right: 15px;
}

.switch-button.toggle-inline {
  display: inline-block;
  vertical-align: middle;
}

.switch-button,
.switch-button .switch {
  width: 91px;
}

.switch-button .switch {
  height: 35px;
}

.switch-button.switch-lg,
.switch-button.switch-lg .switch {
  width: 121px;
}

.switch-button.toggle-inline .switch {
  display: inline-block;
}

.switch-button input {
  display: none;
}

label + .toggle-inline {
  margin-left: 10px;
}

$toggle-2k17-height: 25px;
$toggle-2k17-width: 50px;

/* nex */
.form-group--toggle {
  padding: 7.5px 0;
  font-size: 0;
  // https://stackoverflow.com/questions/10272605/align-two-inline-blocks-left-and-right-on-same-line
  text-align: justify;

  &:after {
    content: '';
    display: inline-block;
    font-size: 0;
    height: 0;
    line-height: 0;
    width: 100%;
  }

  label {
    font-size: $font-size-sm;
    line-height: $toggle-2k17-height;
    margin-bottom: 0; // Reset bootstrap.
    vertical-align: middle;
  }

  [type="checkbox"] {
    display: none;
  }

  .toggle--2k17 {
    display: inline-block;
    vertical-align: middle;
  }
}

.toggle--2k17 {
  height: $toggle-2k17-height; // Very important for JS.
  margin: 5px;
  width: $toggle-2k17-width; // Very important for JS.

  .toggle-slide {
    border-radius: $toggle-2k17-height;
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #999;
  }

  .toggle-on,
  .toggle-off {
    font-size: 11px;
    font-weight: 500;
  }

  .toggle-blob {
    background: #999;
    border-radius: 50px;
  }

  .toggle-on,
  .toggle-select .toggle-inner .active {
    background: rgba($brand-info, 0.3);
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  }

  .toggle-off,
  .toggle-select .toggle-on {
    color: rgba(0, 0, 0, 0.6);
    background: #cfcfcf;
  }

  &.toggle--active {
    .toggle-slide {
      box-shadow: 0 0 0 3px #fff, 0 0 0 5px $brand-info;
    }

    .toggle-blob {
      background-color: $brand-info;
    }
  }
}
