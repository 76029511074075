.offie-container {
  &:before {
    content: '';
    height: 100%;
    left: 0;
    position: fixed;
    opacity: 0;
    top: 0;
    transition: opacity 0.3s, transform 0.3s, visibility 0s 0.3s;
    visibility: hidden;
    width: 100%;
    z-index: $backdrop-zindex;
  }

  &.offie--active {
    &:before {
      opacity: 1;
      transition-delay: 0s;
      visibility: visible;
    }
  }
}

.offie-layer {
  @include make-offie-layer(#fff, 340px, 1002);
  @include make-offie-layer-effect1();
  border: 1px solid $border-color;
  border-bottom-width: 0;
  border-top-width: 0;
  padding-bottom: 50px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.offie-layer--secondary {
  @include make-offie-layer-effect2();

  width: 640px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.offie-layer-body {
  height: 100%;
  padding: 20px;
  position: relative;
}

.offie-dropdown {
  @include make-offie-dropdown(#fff, 340px, 1002);

  box-shadow: -2px 2px 6px 0 rgba(0, 0, 0, 0.075)
}

.offie-dropdown-body {
  background-color: #fff;
  padding: 20px;
}

.offie-collapse {
  height: 0;
  opacity: 0;
  transition: all 0.25s;
  visibility: hidden;

  &.offie--active {
    opacity: 1;
    visibility: visible;
  }
}
