$aside-left-bg-color:               $brand-dark;
$aside-left-font-family:            'Open Sans', Helvetica, Arial, sans-serif;
$aside-left-font-size:              $font-size-sm;
$aside-left-font-weight:            400;
$aside-left-letter-spacing:         0.075em;
$aside-left-item-color:             #ccc;
$aside-left-item-color-hover:       #fff;
$aside-left-item-hover-bg:          rgba($aside-left-item-color-hover, 0.1);
$aside-left-item-color-active:      $brand-primary;
$aside-left-item-text-color-active: #fff;
$aside-left-icon-color:             #ccc;
$aside-left-icon-size:              16px;
$aside-left-position-top:           0;
$aside-left-width:                  200px;
$aside-left-width-collapsed:        60px;

$backdrop-bg-color:                 $brand-dark;

$body-bg-color:                     $brand-light-dk;

$content-bg-color:                  #fff;

$navbar-app-bg-color:               $brand-light;
$navbar-app-brand-bg-color:         $brand-primary;
$navbar-app-header-width:           $aside-left-width;
$navbar-app-border:                 $brand-light-dker;
$navbar-app-shadow:                 0 1px 0 0 rgba($brand-dark, 0.07);
$navbar-app-knob-bg:                $brand-primary;
$navbar-app-knob-color:             #fff;

$navbar-app-item-color:             $text-color;
$navbar-app-item-color-hover:       $text-color;

$navbar-app-item-hover-bg:          $brand-light-dk;

$navbar-app-item-text-color-active: $text-color;

$navbar-app-icon-size:              20px;
$navbar-app-icon-color:             $navbar-app-item-color;
$navbar-app-icon-placeholder:       $navbar-app-item-color;

$section-aside-header-bg-color:     #fff;
