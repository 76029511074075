.datetimepicker {
  margin-top: 0;
  padding: 0;
}

.datetimepicker table {
  width: 220px;
}

.datetimepicker:before {
  border-bottom-color: #e4e9ec;
}

.datetimepicker .active {
  background: none !important;
  background-color: #497fdc !important;
  font-weight: bold;
}

.datetimepicker thead {
  border-bottom: 2px solid #e0e0e0;
  font-size: 0.85em;
}

.datetimepicker .switch {
  text-transform: uppercase;
}

.datetimepicker td,
.datetimepicker th,
.datetimepicker table tr td span {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
  border-radius: 0;
}

.datetimepicker tbody td,
.datetimepicker table tr td span {
  border-style: solid;
  border-color: #e0e0e0;
  border-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
  margin: 0;
  width: 31px;
}

.datetimepicker table tr td span {
  width: 25%;
}

.datetimepicker tbody td[colspan='7'] {
  padding: 0;
}

.datetimepicker tbody tr td:last-child,
.datetimepicker table tr td span:nth-child(4),
.datetimepicker table tr td span:nth-child(8),
.datetimepicker table tr td span:nth-child(12) {
  border-right-width: 0;
}

.datetimepicker table tr td.day {
  width: 31px;
}

.datetimepicker table tr td.day:hover,
.datetimepicker table tr td span:hover {
  background-color: #e6edfa;
}

.datetimepicker .old,
.datetimepicker .new {
  background-color: #eeeeee;
}

.datetimepicker .datetimepicker-hours span,
.datetimepicker .datetimepicker-minutes span {
  height: 35px;
  line-height: 35px;
}
